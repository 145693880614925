<template>
    <div class="pagebox">
        <div class="page_C">
            <cnav :navindex="navindex"></cnav>
            <main><router-view name="entrance"/></main>
            <cfooter></cfooter>
        </div>
    </div>
</template>




<script>
    export default {
        name: 'entrance',
        data() {
            return {
                navindex: 'index',
            }
        },
        created() {

            var path = this.$route.name;
            console.log(path);
            this.navindex = path;

            if(path == 'entrance'){
                this.$router.push({name: 'index'});
                this.navindex = 'index';
            }

        },
    }
</script>




<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss" type="text/css">
    .pagebox{
        width: 100%;
    }
    main{
        padding-top: 75px;
    }
</style>